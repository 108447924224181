import {HTTP} from '../utils/axios'

class LinkAddrsModel extends HTTP {
  /*首页渲染*/
  index() {
    return this.request({
        url: 'board/link_addrs',
        method: "GET",
    })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/link_addrs",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/link_addrs/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
      return this.request({
          url: "board/link_addrs/" + id,
          method: "DELETE"
      })
  }

  /*批量删除*/
  destroy_all() {
    return this.request({
        url: "board/link_addrs/batch_destroy",
        method: "DELETE"
    })
  }
}

export {LinkAddrsModel}