import {HTTP} from '../utils/axios'

class SettingModel extends HTTP {
  /*首页渲染*/
  index() {
    return this.request({
        url: 'board/settings',
        method: "GET",
    })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/settings",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/settings/" + id,
        method: "PATCH",
        data: value,
    })
  }
}

export {SettingModel}