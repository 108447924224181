<template>
  <div>
    <a-row
      class="sm-mt-20">
      <a-col
        :span="7">
      </a-col>
      <a-col
        :span="9">
        <a-form
          :form="form2"
          :label-col="{ span: 6}"
          @submit="handlePost"
          :wrapper-col="{ span: 18 }">
          <a-form-item
            label="公司名称">
            <a-input
              v-decorator="['name', {rules: [{ required: true, message: '请输入公司名称!' }] }]" />
          </a-form-item>
          <a-form-item
            label="上传公司logo">
            <span
              class="redFont">支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图片尺寸320*93px</span>
            <a-upload
              name="logo"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload1">
              <img
                v-if="imageUrl1"
                :src="imageUrl1"
                alt="avatar"
                class="logo" />
              <div
                v-else>
                <a-icon
                  :type="loading ? 'loading' : 'plus'" />
                <div
                  class="ant-upload-text">
                  点击上传
                </div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item
            label="公司地址">
            <a-input
              v-decorator="['addr', {rules: [{ required: true, message: '请输入公司地址!' }] }]" />
          </a-form-item>
          <a-form-item
            label="公司邮箱">
            <a-input
              v-decorator="['email', {rules: [{ required: true, message: '请输入公司邮箱!' }] }]" />
          </a-form-item>
          <a-form-item
            label="公司电话">
            <a-input
              v-decorator="['phone', {rules: [{ required: true, message: '请输入公司电话!' }] }]" />
          </a-form-item>
          <a-form-item
            label="备案编号">
            <a-input
              v-decorator="['serial_number', {rules: [{ required: true, message: '请输入备案编号!' }] }]" />
          </a-form-item>
          <a-form-item
            label="copyright">
            <a-input
              v-decorator="['copyright', {rules: [{ required: true, message: '请输入copyright!' }] }]" />
          </a-form-item>
          <a-form-item
            label="公司传真">
            <a-input
              v-decorator="['fax', {rules: [{ required: true, message: '请输入公司传真!' }] }]" />
          </a-form-item>
          <a-form-item
            label="链接地址">
            <span
              class="tip">链接地址不超过8个</span>
            <a-button
              type="primary"
              @click="showModal('new')">
              新增
            </a-button>
            <a-table
              class="sm-mt-20 link_addr"
              :columns="columns"
              :dataSource="data"
              :pagination="false"
              :loading="loading"
              bordered>
              <span
                slot="action"
                slot-scope="text, record">
                <a href="javascript:;"
                  @click="handleById(record, 'update')">编辑</a>
                <a-divider
                  type="vertical" />
                <a href="javascript:;"
                  @click="handleDelete(record)"
                  class="delete">删除</a>
              </span>
            </a-table>
          </a-form-item>
          <a-form-item
            label="二维码">
            <span
              class="redFont">支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图片尺寸120*120px</span>
            <a-upload
              name="wechat"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload2">
              <img
                v-if="imageUrl2"
                :src="imageUrl2"
                alt="avatar"
                class="wechat" />
              <div
                v-else>
                <a-icon
                  :type="loading ? 'loading' : 'plus'" />
                <div
                  class="ant-upload-text">
                  点击上传
                </div>
              </div>
            </a-upload>
            <div
              class="upload-word">
              <span>公众号二维码</span>
            </div>
          </a-form-item>
          <a-form-item
            :wrapper-col="{ span: 18, offset: 6 }">
            <a-button
              type="danger"
              html-type="button"
              @click="handleClear">
              清空设置
            </a-button>
            <a-button
              :style="{ marginLeft: '20px' }"
              type="primary"
              html-type="submit">
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col
        :span="8">
      </a-col>
    </a-row>
    <a-modal
      title="新增链接"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消">
      <a-form
        :form="form1"
        :label-col="{ span: 6}"
        :wrapper-col="{ span: 18 }">
        <a-form-item
          label="链接名称">
          <a-input
            v-decorator="['name', {rules: [{ required: true, message: '请输入链接名称!' }] }]" />
        </a-form-item>
        <a-form-item
          label="链接地址">
          <a-input
            v-decorator="['addr', {rules: [{ required: true, message: '请输入链接地址!' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { SettingModel } from "../../models/setting";
import { LinkAddrsModel } from "../../models/linkAddr";
import upload from '../../utils/oss'
const settingModel = new SettingModel();
const linkAddrsModel = new LinkAddrsModel();
import { mapMutations } from "vuex";
const columns = [
  {
    title: "链接名称",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "链接地址",
    dataIndex: "addr",
    key: "addr"
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action"
    }
  }
];
export default {
  data () {
    return {
      data: [],
      columns,
      visible: false,
      imageUrl1: "",
      imageUrl2: "",
      loading: false,
      addrId: 0,
      id: 0,
      form2: this.$form.createForm(this, { name: "coordinated2" }),
      form1: this.$form.createForm(this, { name: "coordinated1" }),
    };
  },

  created () {
    settingModel
      .index()
      .then(res => {
        if (res.result.length > 0) {
          this.imageUrl1 = res.result[0].logo;
          this.imageUrl2 = res.result[0].wechat;
          this.id = res.result[0].id;
          this.$nextTick(() => {
            this.form2.setFieldsValue({
              name: res.result[0].name,
              email: res.result[0].email,
              addr: res.result[0].addr,
              phone: res.result[0].phone,
              serial_number: res.result[0].serial_number,
              fax: res.result[0].fax,
              copyright: res.result[0].copyright
            });
          });
          return res.result;
        }
      })
      .then(res => {
        if (res) {
          this.queryLinkList();
        }
      });
  },

  methods: {
    handlePost () {
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.logo = this.imageUrl1;
          values.wechat = this.imageUrl2;
          if (this.id > 0) {
            settingModel.update(values, this.id).then(res => {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              });
            });
          } else {
            settingModel.create(values).then(res => {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              });
            });
          }
        }
      });
    },

    handleById (record, flag) {
      this.flag = flag;
      this.visible = true;
      this.addrId = record.id;
      this.$nextTick(() => {
        this.form1.setFieldsValue({
          name: record.name,
          addr: record.addr
        });
      });
    },

    handleDelete (record) {
      const confirmText = ["确定删除当前链接地址吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          linkAddrsModel.destroy(record.id).then(res => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              });
              this.queryLinkList();
            }
          });
        },
        onCancel () { }
      });
    },

    handleClear () {
      this.form2.resetFields();
      this.imageUrl1 = "";
      this.imageUrl2 = "";
      this.data = []
      linkAddrsModel.destroy_all().then(res => {
        console.log(res)
      })
    },

    handleCancel () {
      this.form1.resetFields();
    },

    handleOk (e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          values.company_setting_id = this.id;
          if (this.flag == "new") {
            linkAddrsModel.create(values).then(res => {
              if (res.code == 0) {
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2
                });
                this.visible = false;
                this.queryLinkList();
              }
            });
          } else {
            linkAddrsModel.update(values, this.addrId).then(res => {
              if (res.code == 0) {
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2
                });
                this.visible = false;
                this.queryLinkList();
              }
            });
          }
        }
      });
    },

    showModal (flag) {
      if (this.data.length == 8) {
        this.$notification.open({
          message: "提示",
          description: "链接地址不能超过8个",
          duration: 2
        });
      } else {
        this.visible = true;
        this.flag = flag;
        this.form1.resetFields();
      }
    },

    beforeUpload1 (file) {
      this.loading1 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading1 = false
        if (res) {
          this.imageUrl1 = res
        }
      })
    },

    beforeUpload2 (file) {
      this.loading2 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading2 = false
        if (res) {
          this.imageUrl2 = res
        }
      })
    },

    queryLinkList () {
      this.loading = true;
      linkAddrsModel.index().then(res => {
        if (res.code == 0) {
          res.result.map((item, index) => {
            item.key = index;
            return item;
          });
          this.data = res.result;
          this.loading = false;
        }
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME"
    })
  }
};
</script>

<style lang="scss" scoped>
.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  color: #333;
}

.tip {
  color: #ff0000;
  display: inline-block;
  width: 270px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.logo {
  width: 320px;
}

.wechat {
  width: 120px;
}
</style>